import React from "react";
import styled from "styled-components";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
import SeedIcon from "../../assets/svg/Services/Seeding";
import PlantIcon from "../../assets/svg/Services/Plant";
import TreeIcon from "../../assets/svg/Services/Tree";
import CheckMark from "../../assets/svg/Checkmark";

export default function PricingTable({ icon, price, title, text,  offers, action }) {
  let getIcon;

  switch (icon) {
    case "seed":
      getIcon = <SeedIcon />;
      break;
    case "plant":
      getIcon = <PlantIcon />;
      break;
    case "tree":
      getIcon = <TreeIcon />;
      break;
  }

  return (
    <Wrapper className="whiteBg radius8 shadow">
      <div className="flexSpaceCenter">
        {getIcon}
        <p className="font25 extraBold">{price}</p>
      </div>
      <div style={{ margin: "30px 0" }}>
        <h4 className="font30 extraBold">{title}</h4>
        <p className="font13">{text}</p>
      </div>
      <div>
        {offers
          ? offers.map((item, index) => (
              <div className="flexNullCenter" style={{ margin: "15px 0" }} key={index}>
                <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
                  {item.cheked ? (
                    <div style={{ minWidth: "20px" }}>
                      <CheckMark />
                    </div>
                  ) : (
                    <div style={{ minWidth: "20px" }}></div>
                  )}
                </div>
                <p className="font15 extraBold">{item.name}</p>
              </div>
            ))
          : null}
      </div>
      <div style={{ maxWidth: "200px", margin: "30px auto 0 auto" }}>
          <BtnWrapper>
            <a href="https://wa.me/5511914005513" >Comece Agora!</a>
          </BtnWrapper>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;

const BtnWrapper = styled.div`
  max-width: 190px;
  background-color: #008101;
  height: 45px;
  border-radius: 15px;

  :hover {
    background-color: #093b1b;
    }

  & a {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    line-height: 45px;
    display: block;
    text-decoration: none;
  }
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;